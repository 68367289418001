import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import httpclient from './plugins/httpclient';
import store from './plugins/store.js';
import { DialogPlugin } from '@syncfusion/ej2-vue-popups';
import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';
import { isNullOrUndefined } from '@syncfusion/ej2-base';

Vue.use(DialogPlugin);
Vue.use(ButtonPlugin);
Vue.prototype.$http = httpclient;

new Vue({
  httpclient,
  store,
  vuetify,
  router,
  DialogPlugin,
  ButtonPlugin,
  isNullOrUndefined,
  render: h => h(App)
}).$mount('#app')

