import Vue from 'vue'
import 'es6-promise/auto'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

const vueStore = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        user: {
            isAuthenticated: false,
            twoFacorAuthenticated: false,
            token: '',
            userName: ''
        }
    },
    mutations: {
        RevokeToken(state) {
            state.user.token = ''
            state.user.isAuthenticated = false;
            state.user.twoFacorAuthenticated = false;
            state.user.userName = '';
            state.user.locationAccessId = '';
            state.user.isClusterLogin = '';
        },
        SetAuth(state, payload) {
            state.user.token = payload.token;
            state.user.isAuthenticated = true;
            state.user.twoFacorAuthenticated = true;
            state.user.userName = payload.userName;
            state.user.locationAccessId = payload.locationsAccessId;
            state.user.isClusterLogin = payload.isCluster;
        }
    },

    getters: {
        user(state) { return state.user },
     }
})


//function getCookie(cname) {
//    var name = cname + "=";
//    var decodedCookie = decodeURIComponent(document.cookie);
//    var ca = decodedCookie.split(';');
//    for (var i = 0; i < ca.length; i++) {
//        var c = ca[i];
//        while (c.charAt(0) == ' ') {
//            c = c.substring(1);
//        }
//        if (c.indexOf(name) == 0) {
//            return c.substring(name.length, c.length);
//        }
//    }
//    return "";
//}

export default vueStore

