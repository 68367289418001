import axios from 'axios'
import store from '../plugins/store.js'
import Vue from 'vue';
import router from '../router'

Vue.use(router);

const instance = axios.create({
    //baseURL: 'https://localhost:44350/api/',
    baseURL: 'https://belino.azurewebsites.net/api/',
    headers: {
        'X-Frame-Options': 'ALLOWALL',
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(
    config => {
        config.headers['Authorization'] = 'Bearer ' + store.getters.user.token;
        return config;
    }
);


instance.interceptors.response.use(response => {
  return response;
},
  error => {
    if (error.response.status === 401) {
      store.commit('RevokeToken');
      router.push("/Login")
    }
    return Promise.reject(error)
  }
);

export default instance