


<template>
  <v-app v-cloak>
    <v-app-bar light app v-if="isLoggedIn">
      <!--v-if="isLoggedIn">-->
      <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      <v-toolbar-side-icon style="cursor:pointer">
        <router-link :to="isClusterLogin ? '/InventoryPullSheets' : '/'">
          <v-img class="mr-3" src="./assets/BelinoLogo.png" height="47px" width="50px">
          </v-img>
        </router-link>
      </v-toolbar-side-icon>
      <v-toolbar-title class="headerFont">Web Management Portal</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="!isClusterLogin && isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/" v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </template>
        <span>Dashboard Home</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!isClusterLogin && isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/LocationManagment" v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-office-building-marker</v-icon>
          </v-btn>
        </template>
        <span>Location Management</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!isClusterLogin && isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/ItemManagement" v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-sitemap</v-icon>
          </v-btn>
        </template>
        <span>Item Management</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!isClusterLogin && isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/InventoryItemManagement" v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-google-circles-communities</v-icon>
          </v-btn>
        </template>
        <span>Inventory Item Management</span>
      </v-tooltip>


      <v-tooltip bottom v-if="!isClusterLogin && isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/Reports" v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-chart-box</v-icon>
          </v-btn>
        </template>
        <span>Reports</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!isClusterLogin && isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/Accounts" v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-office-building</v-icon>
          </v-btn>
        </template>
        <span>Accounts</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!isClusterLogin && isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on"
                 to="/MyProfile">
            <v-icon>mdi-account-cog</v-icon>
          </v-btn>
        </template>
        <span>My Profile</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!isClusterLogin && isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/UserManagement"
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-account-group</v-icon>
          </v-btn>
        </template>
        <span>Manage Users</span>
      </v-tooltip>


      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs"
                 v-on="on" v-on:click="LogOff">
            <v-icon>mdi-shield-lock</v-icon>
          </v-btn>
        </template>
        <span>Sign Out</span>
      </v-tooltip>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>




<script>


    export default {
        name: 'App',
        components: {

        },
        data: () => ({
            drawer: false,
            group: null,
            sidebarMenu: true,
            toggleMini: false
        }),
        computed: {
            isLoggedIn: function () { return this.$store.getters.user.isAuthenticated },
            isClusterLogin: function () { return this.$store.getters.user.isClusterLogin}
        },
        methods: {
            LogOff: function () {
                this.$store.commit('RevokeToken');
                this.$router.push('/Login');
            }
        }
    }
</script>

<style>
  [v-cloak] {
    display: none;
  }
</style>


