import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../plugins/store.js'


Vue.use(VueRouter)

const routes = [

  {
    path: '/Accounts',
    name: 'Accounts-Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Accounts.vue')
    },
    {
        path: '/',
        component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
        meta: {
          allowAnonymous: false
        }
    },
    {
        path: '/MyProfile',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyProfile.vue'),
        meta: {
            allowAnonymous: false
        }
    },
    {
        path: '/InventoryPullSheets',
        component: () => import(/* webpackChunkName: "about" */ '../views/InventoryPullSheets.vue'),
        meta: {
            allowAnonymous: false
        }
    },
    {
      path: '/SyncSquareAcct',
      name:'SquareSync:code',
      component: () => import(/* webpackChunkName: "about" */ '../views/SquareSync.vue'),
      meta: {
        allowAnonymous: false
      }
    },

    {
      path: '/Reports',
      component: () => import(/* webpackChunkName: "about" */ '../views/Orders.vue'),

      meta: {
        allowAnonymous: false
      }
    },
    {
      path: '/UserManagement',
      component: () => import(/* webpackChunkName: "about" */ '../views/ManageUsers.vue'),
      meta: {
        allowAnonymous: false
      }
    },
    {
        path: '/Login',
        name:'Login-2FR:DuoResponse',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
        meta: {
          allowAnonymous: true
        }
    },
    {
    path: '/LocationManagment',
    component: () => import(/* webpackChunkName: "about" */ '../views/LocationManagement.vue'),
    meta: {
        allowAnonymous: false
    }
    },
    {
        path: '/InventoryItemManagement',
        component: () => import(/* webpackChunkName: "about" */ '../views/InventoryItemManagement.vue'),
        meta: {
            allowAnonymous: false
        }
    },
    {
      path: '/ItemManagement',
      component: () => import(/* webpackChunkName: "about" */ '../views/ItemManagement.vue'),
      meta: {
        allowAnonymous: false
      }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve((to, from, next) => {

    if (!to.meta.allowAnonymous && (!store.state.user.isAuthenticated)) {
        next({
            path: '/Login',
            query: { redirect: to.fullPath }
        })
    }
    else {
        if (store.state.user.isClusterLogin && to.path != "/InventoryPullSheets") {
            next("/InventoryPullSheets")
        } else {
            next()
        }
    }
})

export default router
