var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{},[(_vm.isLoggedIn)?_c('v-app-bar',{attrs:{"light":"","app":""}},[_c('v-toolbar-side-icon',{staticStyle:{"cursor":"pointer"}},[_c('router-link',{attrs:{"to":_vm.isClusterLogin ? '/InventoryPullSheets' : '/'}},[_c('v-img',{staticClass:"mr-3",attrs:{"src":require("./assets/BelinoLogo.png"),"height":"47px","width":"50px"}})],1)],1),_c('v-toolbar-title',{staticClass:"headerFont"},[_vm._v("Web Management Portal")]),_c('v-spacer'),(!_vm.isClusterLogin && _vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}],null,false,4158761992)},[_c('span',[_vm._v("Dashboard Home")])]):_vm._e(),(!_vm.isClusterLogin && _vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/LocationManagment"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-office-building-marker")])],1)]}}],null,false,3658383416)},[_c('span',[_vm._v("Location Management")])]):_vm._e(),(!_vm.isClusterLogin && _vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/ItemManagement"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sitemap")])],1)]}}],null,false,658651510)},[_c('span',[_vm._v("Item Management")])]):_vm._e(),(!_vm.isClusterLogin && _vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/InventoryItemManagement"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-google-circles-communities")])],1)]}}],null,false,297942006)},[_c('span',[_vm._v("Inventory Item Management")])]):_vm._e(),(!_vm.isClusterLogin && _vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/Reports"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chart-box")])],1)]}}],null,false,3996412654)},[_c('span',[_vm._v("Reports")])]):_vm._e(),(!_vm.isClusterLogin && _vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/Accounts"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-office-building")])],1)]}}],null,false,1761621710)},[_c('span',[_vm._v("Accounts")])]):_vm._e(),(!_vm.isClusterLogin && _vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/MyProfile"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-cog")])],1)]}}],null,false,3704373951)},[_c('span',[_vm._v("My Profile")])]):_vm._e(),(!_vm.isClusterLogin && _vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/UserManagement"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-group")])],1)]}}],null,false,2603760150)},[_c('span',[_vm._v("Manage Users")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.LogOff}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-lock")])],1)]}}],null,false,3347904914)},[_c('span',[_vm._v("Sign Out")])])],1):_vm._e(),_c('v-main',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }